import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import ImageSection from '@atoms/ImageSection'

const CoverImage = ({ title, full, coverImage }) => {
  return (
    <S.Container>
      {coverImage && (
        <ImageSection
          full={full}
          image={coverImage.childImageSharp.gatsbyImageData}
          alt={title}
        />
      )}
    </S.Container>
  )
}

const S = {}

S.Container = styled.div`
  ${breakpoints({
    xs: css`
      width: 100vw;
      margin-left: -30;
      margin-right: -30;
      padding-bottom: 50;
    `,
    md: css`
      width: 100%;
      margin-left: 30;
      margin-right: 30;
    `,
  })}
  text-align: center;
`

export default CoverImage
