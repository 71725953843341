import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'

const S = {}

S.ContentContainer = styled.divBox`
  ${breakpoints({
    xs: css`
      margin-bottom: 50;
    `,
    md: css`
      margin-bottom: 130;
      max-width: 588; 
    `
  })}
`

const ContentContainer = props => <S.ContentContainer {...props} />

export default ContentContainer
