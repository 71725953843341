import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { H3 } from '@atoms/Typography'
import Avatar from './Avatar'
import CoverImage from './CoverImage'

const Hero = ({ data }) => {
  const { title, author, coverImage, date_published: datePublished } = data

  return (
    <>
      <S.Header>
        <S.Title as="h1">{title}</S.Title>
        <Avatar author={author} datePublished={datePublished} />
      </S.Header>
      {coverImage && <CoverImage full title={title} coverImage={coverImage} />}
    </>
  )
}

const S = {}

S.Header = styled.header`
  ${breakpoints({
    xs: css`
      padding-bottom: 50;
    `,
    md: css`
      max-width: 690;
    `,
  })}
`

S.Title = styled(H3)`
  ${breakpoints({
    xs: css`
      padding-bottom: 20;
    `,
    md: css`
      padding-bottom: 30;
    `,
  })}
`

export default Hero
