import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import HTMLContent from '@atoms/HTMLContent'
import Avatar from './Avatar'

const Author = ({ data }) => {
  const { html } = data
  return (
    <S.Container>
      <S.Avatar author={data} />
      <S.HTMLContent dangerouslySetInnerHTML={{ __html: html }} />
    </S.Container>
  )
}

const S = {}

S.Container = styled.divBox`
  ${breakpoints({
    xs: css`
      margin-bottom: 50;
    `,
    md: css`
      margin-bottom: 65;
    `,
  })}
`

S.Avatar = styled(Avatar)`
  font-weight: ${th.fontWeight('medium')};
  ${breakpoints({
    xs: css`
      margin-bottom: 15;
    `,
    md: css`
      margin-bottom: 20;
    `,
  })}
`

S.HTMLContent = styled(HTMLContent)`
  line-height: 1.5;
  ${breakpoints({
    xs: css`
      font-size: 15;
      a > {
        font-size: 15;
      }
    `,
    md: css`
      font-size: 18;
      a > {
        font-size: 18;
      }
    `,
  })}
`

export default Author
