import React from 'react'
import { isEmpty } from 'lodash'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { transparentize } from 'polished'

const Divider = ({ children, ...props }) => {
  return (
    <>
      {isEmpty(children) ? (
        <S.Divider {...props} />
      ) : (
        <S.DividerTextContainer {...props}>
          <S.DividerText>{children}</S.DividerText>
        </S.DividerTextContainer>
      )}
    </>
  )
}

const S = {}

const dividerStyle = css`
  display: block;
  height: 0;
  padding: 0;
  margin: 0;
  border: 1px solid ${th('colors.greyInvert')};

  ${breakpoints({
    xs: css`
      display: block;
    `,
    md: css`
      display: none;
    `,
  })}
`

S.Divider = styled.hrBox`
  ${dividerStyle}
`

S.DividerTextContainer = styled.div`
  ${dividerStyle}

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    border: 1px solid ${transparentize('0.75', theme.colors.light)};
  `};
`

S.DividerText = styled.span`
  text-align: center;
  background: ${th('colors.dark')};
  font-weight: ${th.fontWeight('bold')};
  padding-left: 15;
  padding-right: 15;
`

export default Divider
