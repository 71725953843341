import React from 'react'
import { graphql } from 'gatsby'
import { isEmpty } from 'lodash'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { Container } from '@atoms/Grid'
import Layout from '@organisms/Layout'
import Hero from '@organisms/Post/Hero'
import Social from '@organisms/Post/Social'
import Author from '@organisms/Post/Author'
import ContentContainer from '@organisms/Post/ContentContainer'
import Related from '@organisms/Post/Related'
import HTMLContent from '@atoms/HTMLContent'
import Divider from '@atoms/Divider'

const BlogPost = ({ data }) => {
  const { meta, author, date, date_published, date_modified } =
    data.post.frontmatter
  const { html } = data.post

  return (
    <Layout
      hamburgerMenu
      invertColor
      content="Blog"
      meta={meta}
      author={author}
      date={date}
      date_published={date_published}
      date_modified={date_modified}
    >
      <S.Main>
        <S.Container>
          <Hero data={data.post.frontmatter} />

          <ContentContainer>
            <S.HTMLContent dangerouslySetInnerHTML={{ __html: html }} />

            <S.Divider />

            <Social />
            <Author data={author} />
          </ContentContainer>

          {!isEmpty(data.relatedPosts.nodes) && (
            <Related
              data={data.relatedPosts}
              defaultImage={data.defaultImage}
            />
          )}
        </S.Container>
      </S.Main>
    </Layout>
  )
}

const S = {}

S.Main = styled.mainBox`
  background: ${th('colors.dark')};
  color: ${th('colors.light')};
`

S.Container = styled(Container)`
  ${breakpoints({
    xs: css`
      padding-top: 160;
      padding-bottom: 100;
    `,
    md: css`
      padding-top: 252;
      padding-bottom: 130;
      display: flex;
      flex-direction: column;
      align-items: center;
    `,
  })}
`
S.HTMLContent = styled(HTMLContent)`
  iframe {
    ${breakpoints({
      xs: css`
        width: 100%;
        max-width: 100%;
        margin-top: 50;
        margin-bottom: 50;
        display: flex;
      `,
      md: css`
        margin-top: 65;
        margin-bottom: 65;
      `,
    })}
  }

  pre {
    ${breakpoints({
      xs: css`
        overflow: auto;
        width: 100%;
        display: inline-block;
      `,
    })}
  }

  blockquote {
    ${breakpoints({
      xs: css`
        font-size: 20;
        font-style: normal;
        line-height: 1.5;
        border-left: 5px solid ${th('colors.pink')};
        padding-left: 30;
        display: flex;
        margin-top: 50;
        margin-bottom: 50;
        width: 100%;
        max-width: 100%;
      `,
      md: css`
        font-size: 32;
        margin-top: 65;
        margin-bottom: 65;
        max-width: 792;
      `,
      lg: css`
        margin-left: -65;
        width: 100vw;
      `,
    })}
  }

  ul {
    list-style-type: none;
  }

  ${breakpoints({
    xs: css`
      margin-bottom: 50;
      h2 {
        font-size: 20;
        font-weight: ${th.fontWeight('bold')};
        margin-bottom: 25;
      }
    `,
    md: css`
      margin-bottom: 65;
      h2 {
        font-size: 32;
        margin-bottom: 30;
      }
    `,
  })}
`

S.Divider = styled(Divider)`
  display: block;
  ${breakpoints({
    xs: css`
      margin-bottom: 50;
    `,
    md: css`
      margin-bottom: 65;
    `,
  })}
`

export const pageQuery = graphql`
  query ($slug: String!, $date: Date) {
    post: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      rawMarkdownBody
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        date_published: date(formatString: "YYYY-MM-DD")
        date_modifed: date(formatString: "YYYY-MM-DD")
        slug
        title
        excerpt
        meta {
          description
          title
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        ...AuthorFragment
        coverImage {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
      }
    }
    relatedPosts: allMarkdownRemark(
      filter: {
        frontmatter: { collection: { eq: "blog" }, date: { lt: $date } }
      }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 3
    ) {
      nodes {
        id
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          date_published: date(formatString: "YYYY-MM-DD")
          date_modified: date(formatString: "YYYY-MM-DD")
          excerpt
          slug
          coverImage {
            childImageSharp {
              gatsbyImageData(width: 500, layout: CONSTRAINED, aspectRatio: 1.5)
            }
          }
          ...AuthorFragment
        }
        timeToRead
      }
    }
    defaultImage: file(name: { eq: "crowdform-default-image" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
  }
`

export default BlogPost

export const AuthorFragment = graphql`
  fragment AuthorFragment on MarkdownRemarkFrontmatter {
    author {
      id
      frontmatter {
        profile {
          childImageSharp {
            gatsbyImageData(width: 125, height: 125, layout: FIXED)
          }
        }
        profileThumbnail: profile {
          childImageSharp {
            gatsbyImageData(width: 50, height: 50, layout: FIXED)
          }
        }
        title
      }
      html
    }
  }
`
