import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import Posts from '@organisms/Feed/Posts'

const Related = ({ data, defaultImage }) => {
  return (
    <aside>
      <S.Container>
        <S.Title mb="15">Related</S.Title>
        <Posts data={data} defaultCoverImage={defaultImage} />
      </S.Container>
    </aside>
  )
}

const S = {}

S.Container = styled.sectionBox``

S.Title = styled.h2Box`
  ${breakpoints({
    xs: css`
      font-size: 18;
      font-weight: ${th.fontWeight('black')};
      line-height: 1.4;
    `,
    md: css`
      font-size: 24;
      font-weight: ${th.fontWeight('bold')};
      line-height: 1.5;
    `,
  })}
`

S.Posts = styled(Posts)`
  ${breakpoints({
    md: css`
      padding-top: 65;
    `,
  })};
`

export default Related
