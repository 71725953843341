import styled, { css } from '@xstyled/styled-components'
import { th, breakpoints } from '@xstyled/system'

const HTMLContent = styled.divBox`
  font-size: 18;

  p {
    margin-bottom: 30;
    /* &:last-of-type {
      margin-bottom: 0;
    } */

    > a {
      border-bottom: 1px solid ${th('colors.greyInvert')};
      padding-bottom: 4px;
      transition: all 0.3s ease;
      word-break: break-word;
      &:hover {
        border-color: ${th('colors.light')};
        cursor: pointer;
      }
    }
  }
  ul {
    /* border-left: 5px solid ${th('colors.pink')}; */
    padding-left: 20;
    list-style: disc;
    margin-bottom: 30;

    li {
      margin-bottom: 1rem;
      font-size: inherit;

      ul {
        margin-top: 30;
        margin-bottom: 30;
        border-left: none;
      }
    }
  }

  ol {
    margin-bottom: 30;
    li {
      margin-bottom: 10;
      font-size: 16;
    }
  }

  h1 {
    font-weight: ${th('fontWeights.black')};
    line-height: 1.4;
    margin-bottom: 10;
    ${breakpoints({
      xs: css`
        font-size: 24;
      `,
      md: css`
        font-size: 60;
      `,
    })}
  }

  h2,
  h3 {
    line-height: 1.5;
    font-weight: ${th('fontWeights.bold')};
    margin-bottom: 10;
    ${breakpoints({
      xs: css`
        font-size: 20;
      `,
      md: css`
        font-size: 32;
      `,
    })}
  }

  h3,
  h4 {
    line-height: 1.5;
    font-weight: ${th('fontWeights.bold')};
    margin-bottom: 10;
    ${breakpoints({
      xs: css`
        font-size: 18;
      `,
      md: css`
        font-size: 22;
      `,
    })}
  }

  .video_wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export default HTMLContent
