import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import SocialLinks from '@molecules/Social'

const Social = props => {
  const socialIcons = ['facebook', 'linkedin', 'twitter']
  return (
    <S.Wrapper>
      <S.Container {...props}>
        <S.SocialLinks icons={socialIcons} />
      </S.Container>
    </S.Wrapper>
  )
}

const S = {}

S.Wrapper = styled.section`
  ${breakpoints({
    xs: css`
      margin-bottom: 50;
    `,
    md: css`
      margin-bottom: 65;
    `,
  })}
`

S.Container = styled.divBox`
  display: flex;
  flex-direction: column;
  ${breakpoints({
    xs: css`
      width: 100%;
    `,
    md: css`
      max-width: 588;
    `,
  })}
`

S.SocialLinks = styled(SocialLinks)`
  ${breakpoints({
    xs: css`
      svg {
        width: 24;
        height: 24;
      }
    `,
    md: css`
      svg {
        width: 26.37;
        height: 26.37;
      }
    `,
  })}
`

export default Social
